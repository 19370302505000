<template>
  <custom-actions class="card" title="告警语音配置" show-back>
    <el-form :model="formData" label-width="100px">
      <el-form-item :label="getMsgTypeName(item.msgType)" v-for="(item, index) of items" :key="index">
        <el-select v-if="item.msgType === '1'" v-model="childMsgTypes" multiple clearable>
          <el-option label="瞬时短路" value="瞬时短路"></el-option>
          <el-option label="漏电" value="漏电"></el-option>
          <el-option label="拉弧故障" value="拉弧故障"></el-option>
          <el-option label="短延时短路" value="短延时短路"></el-option>
          <el-option label="过载" value="过载"></el-option>
          <el-option label="过电压" value="过电压"></el-option>
          <el-option label="欠电压" value="欠电压"></el-option>
          <el-option label="超温" value="超温"></el-option>
          <el-option label="缺相" value="缺相"></el-option>
        </el-select>
        <el-switch v-else v-model="item.msgState" active-text="开启" inactive-text="关闭" active-value="1" inactive-value="0" @change="value => saveSetting(item.msgType, value)"></el-switch>
        <el-button class="ml10" v-if="item.msgType === '1'" type="primary" @click="saveSetting(item.msgType, childMsgTypes.join())">保存</el-button>
      </el-form-item>
    </el-form>
  </custom-actions>
</template>

<script>
export default {
  name: 'AlarmSetting',
  data () {
    return {
      msgTypeList: [],
      formData: {
        msgType: '',
        msgState: ''
      },
      items: [],
      childMsgTypes: []
    }
  },
  computed: {
  },
  filters: {
  },
  watch: {
  },
  created () {
    this.getMsgType().then(() => {
      this.loadData()
    })
  },
  methods: {
    loadData () {
      this.$axios.get(this.$apis.device.selectMsgConf).then(res => {
        this.items = res.filter(item => item.msgType !== '5')
        this.childMsgTypes = this.items.find(item => item.msgType === '1').msgState.split(',')
      })
    },
    getMsgTypeName (value) {
      return this.msgTypeList.find(item => item.dicCode === value).dicValue || ''
    },
    saveSetting (msgType, msgState) {
      this.$axios.post(this.$apis.device.msgConf, {
        msgType,
        msgState
      })
    },
    getMsgType () {
      return this.$axios.get(this.$apis.common.selectDicByList, {
        params: { dicType: 'msgType' }
      }).then(res => {
        this.msgTypeList = res.msgType || []
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
